<template>
    <b-container>
        <b-row>
            <b-col>
                <h3 class="mt-3">概要</h3>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-select class="mt-3" v-model="shop" :options="shopOptions"></b-form-select>
            </b-col>
            <b-col>
                <b-form-select class="mt-3" v-model="diffDays" :options="diffDayOptions"></b-form-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="12" lg="3">
                <b-card class="mt-3">
                    <b-card-title>${{ Math.round(grossProfit * 10) / 10 }}</b-card-title>
                    <b-card-sub-title>預計利潤</b-card-sub-title>
                </b-card>
            </b-col>
            <b-col md="12" lg="3">
                <b-card class="mt-3">
                    <b-card-title>${{ Math.round(ordersGrandTotal * 10) / 10 }}</b-card-title>
                    <b-card-sub-title>收入</b-card-sub-title>
                </b-card>
            </b-col>
            <b-col md="12" lg="3">
                <b-card class="mt-3">
                    <b-card-title>${{ Math.round(expensesGrandTotal * 10) / 10 }}</b-card-title>
                    <b-card-sub-title>開支</b-card-sub-title>
                </b-card>
            </b-col>
            <b-col md="12" lg="3">
                <b-card class="mt-3">
                    <b-card-title>{{ orderBySelect.length }}張</b-card-title>
                    <b-card-sub-title>訂單</b-card-sub-title>
                </b-card>
            </b-col>
        </b-row>
         
        <h3 class="mt-3">{{ shopName }}-{{ diffDaysText }}訂單</h3>
        
        <n-list value="orders" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="orderBySelect" :disabledText="true" :disabled="true"/>
      
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    data() {
        return {
            shop: null,
            sortBy: 'uniNum',
            sortDesc: true,
            diffDays: 'today',
            diffDayOptions: [
                {
                    value: 'today',
                    text: '今日',
                },
                {
                    value: 'yesterday',
                    text: '昨日',
                },
                {
                    value: 'thisMonth',
                    text: '今個月',
                },
                {
                    value: 'last_month',
                    text: '上一個月',
                },
                
            ],
            fields: [
                {
                    key: 'uniNum',
                    label: '單號',
                    sortable: true,
                },
                {
                    key: 'source',
                    label: '來源',
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'method',
                    label: '方式',
                    formatter: value => {
                        switch(value) {
                            case 'PICKUP':
                                return '自取'
                            case 'DELIVERY':
                                return '外送'
                        }
                    },
                    sortable: true,
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
                 {
                    key: 'status',
                    label: '狀態',
                    sortable: true,
                    formatter: value => {
                        switch (value) {
                            case 'PENDING':
                                return '待接單'
                            case 'RECEIVED':
                                return '已接單'
                            case 'PENDING_PAYMENT':
                                return '待付款'
                            case 'PACKED':
                                return '待取餐'
                            case 'COMPLETED':
                                return '已完成'
                            case 'VOID':
                                return '已取消'
                        }
                    },
                },
            ]
        }
    },

    async created() {
        await this.$store.dispatch('bindOrdersDashboard')
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'expenses', merchantId: localStorage.merchantId })
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
    },

    watch: {
        async diffDays(newDiffDays){
            this.$store.state.diffDays = newDiffDays
            await this.$store.dispatch('bindOrdersDashboard')
        }      
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            expenses: state => state.expenses,
            shops: state => state.shops,
            toDay: state => state.toDay,
            yesterDay: state => state.yesterDay,
            thisMonthStart: state => state.thisMonthStart,
            thisMonthEnd: state => state.thisMonthEnd,
            lastMonthStart: state => state.lastMonthStart,
            lastMonthEnd: state => state.lastMonthEnd,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.name,
                    value: {
                        id: shop.id,
                        name: shop.name,
                    }
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        shopName() {
            if (!this.shop) return '全部分店'
            return _.find(this.shopOptions, { text: this.shop.name }).text
        },

        orderBySelect(){
           if (!this.orders) return []
           let res = []
              switch(this.diffDays) {
                case 'today': {
                    if (!this.shop){
                        _.cloneDeep(this.orders).forEach(order => {
                            if (order.uniNum >  Number(this.toDay + '000') && order.uniNum < Number(this.toDay + '999') && order.status == 'COMPLETED') return res.push(order)
                        })
                    }
                    else{
                        _.cloneDeep(this.orders).forEach(order => {
                            if (order.uniNum >  Number(this.toDay + '000') && order.uniNum < Number(this.toDay + '999') && order.status == 'COMPLETED' && order.shop.id == this.shop.id) return res.push(order)
                        })
                    } 
                    break  
                }
                case 'yesterday': {
                     if (!this.shop){
                        _.cloneDeep(this.orders).forEach(order => {
                                if (order.uniNum >  Number(this.yesterDay + '000') && order.uniNum < Number(this.yesterDay + '999') && order.status == 'COMPLETED') return res.push(order)
                            })
                        }
                        else{
                            _.cloneDeep(this.orders).forEach(order => {
                                if (order.uniNum >  Number(this.yesterDay + '000') && order.uniNum < Number(this.yesterDay + '999') && order.status == 'COMPLETED' && order.shop.id == this.shop.id) return res.push(order)
                            })
                        }
                        break
                }
                case 'thisMonth': {
                     if (!this.shop){
                        _.cloneDeep(this.orders).forEach(order => {
                            if (order.completedAt > this.thisMonthStart && order.completedAt < this.thisMonthEnd && order.status == 'COMPLETED') return res.push(order)
                        })
                    }
                    else{
                        _.cloneDeep(this.orders).forEach(order => {
                            if (order.completedAt > this.thisMonthStart && order.completedAt < this.thisMonthEnd && order.status == 'COMPLETED' && order.shop.id == this.shop.id) return res.push(order)
                        })
                    } 
                    break  
                }
                case 'last_month': {
                     if (!this.shop){
                        _.cloneDeep(this.orders).forEach(order => {
                            if (order.completedAt > this.lastMonthStart && order.completedAt < this.lastMonthEnd && order.status == 'COMPLETED') return res.push(order)
                        })
                    }
                    else{
                        _.cloneDeep(this.orders).forEach(order => {
                            if (order.completedAt > this.lastMonthStart && order.completedAt < this.lastMonthEnd && order.status == 'COMPLETED' && order.shop.id == this.shop.id) return res.push(order)
                        })
                    } 
                    break  
                }
            }
            return res
        },

        ordersGrandTotal() {
            return _.sumBy(this.orderBySelect, 'grandTotal')
        },

        expensesGrandTotal() {
            switch(this.diffDays) {
                case 'today': {
                    return _.sumBy(this.expensesByMonth(0), 'grandTotal') / moment().daysInMonth()
                }
                case 'yesterday': {
                    return _.sumBy(this.expensesByMonth(0), 'grandTotal') / moment().daysInMonth()
                }
                case 'thisMonth': {
                    return _.sumBy(this.expensesByMonth(0), 'grandTotal')
                }
                case 'last_month': {
                    return _.sumBy(this.expensesByMonth(1), 'grandTotal')
                }
            }
            return 0
        },

        grossProfit() {
            return this.ordersGrandTotal - this.expensesGrandTotal
        },

        diffDaysText() {
            return _.find(this.diffDayOptions, { value: this.diffDays }).text
        },
    },

    methods: { 
        expensesByMonth(diff) {
            if (!this.expenses) return []
            const startDate = moment().subtract(diff, 'months').startOf('month').format('x')
            const endDate = moment().subtract(diff, 'months').endOf('month').format('x')
            let res = []
            _.cloneDeep(this.expenses).forEach(expense => {
                if (expense.paidAt > startDate && expense.paidAt < endDate) return res.push(expense)
            })
            return res
        },
    }
}
</script>